import React, { useLayoutEffect, useState } from "react";
import Modal from "../Modals/index";
import EmailModal from "../Modals/Emailmodal";
import Successmodal from "../Modals/Successmodal";
import moment from "moment";
import Spinner from "../Spinner";
import Master from "../../assets/master.svg";
import Visa from "../../assets/visa.svg";
import { get, post } from "../../utils/axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setExtraCart } from "../../state";
import Bin from "../../assets/binnn.svg";
import { SummaryAggrementmodal } from "../Modals/SummaryAggrementmodal";
import Toast from "../Toast";
import { PgAgreementModal } from "../Modals/PgAgreementModal";

const Summary = ({ switchComponent, setCurrentStep }) => {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.token);
  const storedAddress2 = useSelector((state) => state.address);
  const cartData = useSelector((state) => state.assigned_cart);
  const extraCartData = useSelector((state) => state.extra_cart);

  const selectedVendor = useSelector((state) => state.selectedVendor);
  const user_selected_vendor_id = useSelector(
    (state) => state.user_selected_vendor_id
  );
  let card = JSON.parse(localStorage.getItem("selectedCard"));
  const summaryCardData = JSON.parse(localStorage.getItem("summary_data"));
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const [loader, setLoader] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [emailModal, setShowemailmodal] = useState(false);
  const [emailValues, setEmailValues] = useState([]);
  const [successModal, setShowsuccessModal] = useState(false);
  const [selectedOptionalItems, setSelectedOptionalItems] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const [couponValue, setCouponValue] = useState(null);
  const [couponValueCal, setCouponValueCal] = useState(0);
  const [agreementCheck, setAgreementCheck] = useState(false);
  const [parentalAgreement, setParentalAgreement] = useState(false);
  const [responseSignatureData, setResponseSignatureData] = useState(null);
  const [pgResponse, setPgResponse] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [isPgRequired, setIsPgRequired] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [parentalModal, setParentalModal] = useState(false);
  const [invalidCoupon, setInvalidCoupon] = useState(false);
  const [is_18_select, setIs_18_select] = useState(null);
  const carts = [cartData, ...extraCartData];
  const Payment = "Select Payment Card";
  const Coupon = "Enter Coupon";

  const openModal = () => {
    setShowModal(true);
  };
  const openParentalModal = (value) => {
    setIs_18_select(value);
    setParentalModal(true);
  };

  const handleResponseData = (data) => {
    setResponseData(data);
  };

  const handleResponseSignatureData = (signatureData) => {
    setResponseSignatureData(signatureData);
  };

  useLayoutEffect(() => {
    const fetchVendors = async () => {
      console.log('====================================');
      console.log('response', selectedVendor);
      console.log('====================================');
      try {
        const res = await get(`/vendor/${selectedVendor.id}`, {}, {}, Token);
        console.log('====================================');
        console.log('response', res);
        console.log('====================================');
        if ("response" in res) {
          const response = res.response?.data?.vendor;
          setIsPgRequired(response.is_pg_required);
        }
      } catch (err) {
        console.error("Error fetching vendors:", err);
      }
    };
    fetchVendors();
  }, []);

  const _renderPaymentCard = () => {
    if (card) {
      return (
        <>
          <div className="relative flex rounded-xl w-[90%] p-4 mx-auto shadow-[0_5px_30px_-20px_#000000]">
            <div className="flex">
              <img
                className="w-[49px] h-[49px] ml-[18px]"
                src={card.brand == "Visa" ? Visa : Master}
                alt="Master Card"
              />
              <div className="flex flex-col ml-[16px] text-start">
                <p>{card.brand}</p>
                <p>xxxx-xxxx-xxxx-{card.last_4}</p>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          onClick={() => {
            setActiveComponent("A");
          }}
        >
          <div className={`flex p-5 justify-center`}>
            <p className="text-Textlitecolor">Select Payment Card</p>
          </div>
        </div>
      );
    }
  };

  const Value = [
    {
      onClick: () => setActiveComponent("A"),
      title: "Payment Method",
      content: Payment,
    },
    {
      title: "Coupon",
      content: Coupon,
    },
    {
      title: "Address",
      content: storedAddress2.full_address,
    },
    {
      title: "Main Reservation Date",
      content: `${moment(startDate).format("MMM, DD YYYY")} - ${moment(
        endDate
      ).format("MMM, DD YYYY")}`,
    },
    {
      onClick: () => setShowemailmodal(true),
      title: "Additional Driver(s) Email",
      content: emailValues,
    },
  ];

  const vendorId = selectedVendor?.id ?? user_selected_vendor_id;

  const handleCoupon = () => {
    get(`/discount/coupon/${couponCode}/${vendorId}`, {}, {}, Token)
      .then((res) => {
        if ("response" in res) {
          const message = res.response.data.message;
          const operation = res.response.data.coupon.operation;
          const value = res.response.data.coupon.value;
          if (res.response.data.coupon.value > _renderTotalAmmount().finalTotal) {
            setCouponMessage("Invalid Coupon");
            setCouponCode("");
            setInvalidCoupon(true);
            toast("Invalid Coupon ", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              type: "warning",
            });
          } else {
            setCouponMessage(message);
            setCouponCode("");
            if (operation === "percentage") {
              let percentCoupon = (_renderTotalAmmount().finalTotal * value) / 100;

              setCouponValueCal(percentCoupon);
            } else if (operation === "amount") {
              setCouponValueCal(value);
            }
            setCouponValue(res.response.data.coupon);
            toast("Congrats! Coupon Applied Successfully ", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              type: "success",
            });
          }
        } else {
          // console.log(res);
        }
      })
      .catch((err) => {
        toast(err?.response?.data?.error?.messages[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          type: "error",
        });
      });
  };

  const booking = () => {
    let card = JSON.parse(localStorage.getItem("selectedCard"));
    const diffrance = JSON.parse(localStorage.getItem("daysDifference"));
    if (is_18_select !== null) {
      // setLoader(true);
      if ((card !== null && responseSignatureData !== null) || undefined) {
        let data = {
          booking_type: cartData.cart_type,
          address_id: storedAddress2.id !== undefined ? storedAddress2.id : 0,
          latitude: storedAddress2.latitude,
          longitude: storedAddress2.longitude,
          payment_method: "stripe",
          transaction_reference: card.card_id,
          address_details: storedAddress2.full_address,
          vendor_id: vendorId,
          city:
            storedAddress2.city !== undefined
              ? storedAddress2.city
              : "Rosemary Beach",
          state: storedAddress2.state !== undefined ? storedAddress2.state : "FL",
          country: "USA",
          zip: storedAddress2.zip !== undefined ? storedAddress2.zip : "32461",
          start_date: startDate,
          end_date: endDate,
          signature: responseSignatureData,
          duration: diffrance + 1,
          ...(pgResponse !== null && { parental_form_id: pgResponse }),
          tax_and_charges: taxId,
          final_amount: _renderTotalAmmount().finalTotal,
          booking_details: carts.map((i) => ({
            cart_id: i.id,
            end_date:
              i.end_date !== undefined
                ? moment(i.end_date).format("MM/DD/YYYY")
                : moment(endDate).format("MM/DD/YYYY"),
            start_date:
              i.start_date !== undefined
                ? moment(i.start_date).format("MM/DD/YYYY")
                : moment(startDate).format("MM/DD/YYYY"),
          })),
          invite_users: emailValues,
        };
        if (summaryCardData?.discount !== null) {
          if (discount.value <= calculateTotal().initialAmountWithoutDiscount) {
            data.discount_id = summaryCardData?.discount?.id;
          }
        }
        data.coupon_id = couponValue !== null ? couponValue.id : "";

        post(`/booking/booking`, data, {}, Token)
          .then((res) => {
            if ("response" in res) {
              setLoader(false);

              localStorage.removeItem("selectedCard");
              setShowsuccessModal(true);
              dispatch(setExtraCart([]));
            }
          })
          .catch((err) => {
            setLoader(false);
            Toast({
              message: err?.response?.data?.error?.messages[0],
              isError: true,
            });
          });
      } else {
        Toast({ message: "Please Select Your Payment Card", isError: true });
        setLoader(false);
      }
    } else {
      Toast({ message: "Please sign under 18 riders agreement", isError: true });
      setLoader(false);
    }
  };

  const handleDeleteEmail = (index) => {
    const updatedEmails = [...emailValues];
    updatedEmails.splice(index, 1);
    setEmailValues(updatedEmails);
  };

  let totalDifferenceInDays = 0;
  let totalSubtotal = 0;
  let update = summaryCardData?.tax_and_charges;
  let discount = summaryCardData?.discount;
  let totaldaysDiff = 0
  const isMandatory = (periority) => periority === "mandatory";

  const handleCheckboxChange = (categoryId, itemId) => {
    const selectedItem = `${categoryId}-${itemId}`;

    setSelectedOptionalItems((prevItems) => {
      if (prevItems.includes(selectedItem)) {
        return prevItems.filter((item) => item !== selectedItem);
      } else {
        return [...prevItems, selectedItem];
      }
    });
  };
  let sum = 0;
  let taxId = [];
  let discounts = 0;

  const calculateTotal = (is_update = false) => {
    const total = carts.reduce((acc, item) => {
      const data = item?.price_perday === 0
        ? item.price_perday
        : summaryCardData.seasonal_pricing &&
          Array.isArray(summaryCardData.seasonal_pricing) &&
          summaryCardData.seasonal_pricing.length > 0
          ? summaryCardData.seasonal_pricing.find(i => i.category_id === item.category_id)?.value ?? item.price_perday
          : item.price_perday;

      return acc + (data || 0);
    }, 0);

    let cartsTotal = total;
    let totalInitAmount = cartsTotal * totaldaysDiff;
    let initialAmountWithoutDiscount = 0;
    let initAmount = 0;
    let subTotalAmount = 0;
    let subTotalCalculation = 0;
    let totalWithTaxAmount = 0;
    let beforeFinalTotal = 0;
    let finalTotal = 0;
    let couponAmmount = 0;
    // If is_update is true, reset the calculations and start from initial values
    if (is_update) {
      cartsTotal = total;
      totalInitAmount = cartsTotal * totaldaysDiff;
      initialAmountWithoutDiscount = 0
      initAmount = 0;
      subTotalAmount = 0;
      subTotalCalculation = 0
      totalWithTaxAmount = 0;
      finalTotal = 0;
    }

    // Calculate init_amount taxes and charges
    summaryCardData?.tax_and_charges?.forEach((category) => {
      category.data.forEach((item) => {
        if (item.calculate_on === "init_amount" && (isMandatory(item.periority))) {
          taxId.push(item.id);
          if (item.operation_type === "percent") {
            initAmount += (totalInitAmount * item.value) / 100;
          } else if (item.operation_type === "amount") {
            taxId.push(item.id);
            initAmount += item.value;
          }
        }
        if (item.calculate_on === "init_amount" && selectedOptionalItems.includes(`${category.id}-${item.id}`)) {
          taxId.push(item.id);
          if (item.operation_type === "percent") {
            initAmount += (totalInitAmount * item.value) / 100;
          } else if (item.operation_type === "amount") {
            taxId.push(item.id);
            initAmount += item.value;
          }
        }
      });
    });

    totalInitAmount += initAmount;
    initialAmountWithoutDiscount = totalInitAmount;
    if (discount && discount.value <= initialAmountWithoutDiscount) {
      if (discount.operation_type === "percent") {
        totalInitAmount -= (totalInitAmount * discount.value) / 100;
      } else if (discount.operation_type === "amount") {
        totalInitAmount -= discount.value;
      }
    }
    subTotalAmount = totalInitAmount;
    subTotalCalculation = subTotalAmount;

    summaryCardData?.tax_and_charges?.forEach((category) => {
      category.data.forEach((item) => {
        if (item.calculate_on === "subtotal" && (isMandatory(item.periority))) {
          taxId.push(item.id);
          if (item.operation_type === "percent") {
            subTotalAmount += (subTotalAmount * item.value) / 100;
          } else if (item.operation_type === "amount") {
            subTotalAmount += item.value;
          }
        }
        if (item.calculate_on === "subtotal" && selectedOptionalItems.includes(`${category.id}-${item.id}`)) {
          taxId.push(item.id);
          if (item.operation_type === "percent") {
            subTotalAmount += (subTotalCalculation * item.value) / 100;
          } else if (item.operation_type === "amount") {
            subTotalAmount += item.value;
          }
        }

      });
    });

    totalWithTaxAmount = subTotalAmount;
    beforeFinalTotal = totalWithTaxAmount;
    // Calculate total_with_tax taxes and charges;
    summaryCardData?.tax_and_charges?.forEach((category) => {
      category.data.forEach((item) => {
        if (item.calculate_on === "total_with_tax" && (isMandatory(item.periority))) {
          taxId.push(item.id);
          if (item.operation_type === "percent") {
            totalWithTaxAmount += (totalWithTaxAmount * item.value) / 100;
          } else if (item.operation_type === "amount") {
            totalWithTaxAmount += item.value;
          }
        }
        if (item.calculate_on === "total_with_tax" && selectedOptionalItems.includes(`${category.id}-${item.id}`)) {
          taxId.push(item.id);
          if (item.operation_type === "percent") {
            totalWithTaxAmount += (beforeFinalTotal * item.value) / 100;
          } else if (item.operation_type === "amount") {
            totalWithTaxAmount += item.value;
          }
        }
      });
    });

    finalTotal = totalWithTaxAmount;

    // Apply coupon
    if (couponValue) {
      if (couponValue.operation === "percentage") {
        couponAmmount = (finalTotal * couponValue.value) / 100;
      } else if (couponValue.operation === "amount") {
        couponAmmount = couponValue.value;
      }
    }
    // setCouponValueCal(couponAmmount)
    finalTotal = totalWithTaxAmount - couponAmmount;
    // Return the calculated values
    return {
      cartsTotal,
      totalInitAmount,
      initialAmountWithoutDiscount,
      subTotalAmount,
      totalWithTaxAmount,
      finalTotal,
      subTotalCalculation,
      beforeFinalTotal,
      couponAmmount
    };
  };


  const renderItems = () => {
    if (!update) return null;
    return (
      <div>
        {
          update.map((category) => {
            return (
              <div key={category.id} className="py-[2px] md:text-[16px] text-[12px]">
                <div>
                  {category.data.filter((i) => i.calculate_on == "init_amount").map((item) => {
                    const selectedItem = `${category.id}-${item.id}`;
                    const isSelected = isMandatory(item.periority) || selectedOptionalItems.includes(selectedItem);
                    let sums = 0;

                    if (isSelected) {
                      if (item.operation_type === "percent") {
                        sums = (calculateTotal().cartsTotal * totaldaysDiff * item.value) / 100;
                      } else if (item.operation_type === "amount") {
                        sums = item.value;
                      }
                    }

                    return (
                      <div key={item.id} className="flex justify-between">
                        <p className="text-Textlitecolor py-[2px] text-start">
                          - {item.name} {item.vendor_id === null ? `($${sums.toFixed(2)})` : item.operation_type === "percent" ? `(${item.value}%)` : `($${item.value.toFixed(2)})`}
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={isMandatory(item.periority)}
                          disabled={isMandatory(item.periority)}
                          onChange={() => handleCheckboxChange(category.id, item.id)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          })
        }
        <div className="flex py-[7px] font-bold text-[#e6a62f] justify-between md:text-[16px] text-[12px]">
          <p className="">Sub Total</p>
          <p className="">{`$${parseFloat(
            calculateTotal().initialAmountWithoutDiscount
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}</p>
        </div>
        {discount && (
          <div className="py-[2px]">
            <div>
              <div className="flex justify-start md:text-[16px] text-[12px] gap-1">
                <p className="text-Textlitecolor  text-start">- Discount</p>
                <p className="text-Textlitecolor  text-start ">
                  ($
                  {(discounts =
                    carts.some(item => item?.price_perday > 0) ?
                      discount.operation_type === "percent"
                        ? (
                          (totalSubtotal * discount.value) /
                          100
                        ).toFixed(2)
                        : discount.value.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) : 0)})
                </p>
              </div>
            </div>
          </div>
        )}

        {
          update.map((category) => {
            return (
              <div key={category.id} className="py-[2px] md:text-[16px] text-[12px]">
                <div>
                  {category.data.filter((i) => i.calculate_on == "subtotal").map((item) => {
                    const selectedItem = `${category.id}-${item.id}`;
                    const isSelected = isMandatory(item.periority) || selectedOptionalItems.includes(selectedItem);
                    let sums = 0;

                    if (isSelected) {
                      if (item.operation_type === "percent") {
                        sums = (calculateTotal().subTotalCalculation * item.value) / 100;
                      } else if (item.operation_type === "amount") {
                        sums = item.value;
                      }
                    }
                    return (
                      <div key={item.id} className="flex justify-between">
                        <p className="text-Textlitecolor py-[2px] text-start">
                          {/* - {item.name} {item.operation_type === "percent" && `(${item.value}%)`} = {"$" + sums.toFixed(2)} */}
                          - {item.name} {item.vendor_id === null ? `($${sums.toFixed(2)})` : item.operation_type === "percent" ? `(${item.value}%)` : `($${item.value.toFixed(2)})`}
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={isMandatory(item.periority)}
                          disabled={isMandatory(item.periority)}
                          onChange={() => {
                            calculateTotal(true);
                            handleCheckboxChange(category.id, item.id)
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          })
        }
        <div className="flex py-[7px] font-bold text-[#e6a62f] justify-between md:text-[16px] text-[12px]">
          <p className="">Total With Tax</p>
          <p className="">{`$${parseFloat(
            calculateTotal().subTotalAmount
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}</p>
        </div>
        {
          update.map((category) => {
            return (
              <div key={category.id} className="py-[2px] md:text-[16px] text-[12px]">
                <div>
                  {category.data.filter((i) => i.calculate_on == "total_with_tax").map((item) => {
                    const selectedItem = `${category.id}-${item.id}`;
                    const isSelected = isMandatory(item.periority) || selectedOptionalItems.includes(selectedItem);
                    let sums = 0;

                    if (isSelected) {
                      if (item.operation_type === "percent") {
                        sums = (calculateTotal().beforeFinalTotal * item.value) / 100;
                      } else if (item.operation_type === "amount") {
                        sums = item.value;
                      }
                    }

                    return (
                      <div key={item.id} className="flex justify-between">
                        <p className="text-Textlitecolor py-[2px] text-start">
                          {/* - {item.name} {item.operation_type === "percent" && `(${item.value}%)`} = {"$" + sums.toFixed(2)} */}
                          - {item.name} {item.vendor_id === null ? `($${sums.toFixed(2)})` : item.operation_type === "percent" ? `(${item.value}%)` : `($${item.value.toFixed(2)})`}
                        </p>
                        <input
                          type="checkbox"
                          defaultChecked={isMandatory(item.periority)}
                          disabled={isMandatory(item.periority)}
                          onChange={() => {
                            calculateTotal(true);
                            handleCheckboxChange(category.id, item.id)
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          })
        }
        {couponValue && (
          <div className="py-1">
            <div>
              <div className="flex justify-between md:text-[16px] text-[12px]">
                <p className="text-[#e6a62f] font-semibold">Coupon</p>
                <p className="text-[#e6a62f] font-semibold">
                  {couponValue.operation === "percentage"
                    ? `$${calculateTotal().couponAmmount.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                    : `$${couponValue.value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="flex py-[7px] font-bold text-[#e6a62f] justify-between md:text-[16px] text-[12px]">
          <p className="">Total To Customer</p>
          <p className="">{`$${parseFloat(
            calculateTotal().finalTotal
          ).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`}</p>
        </div>
      </div>
    )
  };

  const _renderTotalAmmount = () => {
    return calculateTotal();
  };

  if (loader) {
    return (
      <div className="flex justify-center items-center w-full lg:h-[670px] md:h-[670px] h-[650px]">
        <Spinner className="spinner" isLoading={loader} />
      </div>
    );
  } else {
    return (
      <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col justify-center items-center w-[100%]  md:h-[670px] h-[87vh]">
        <p className="md:my-[27px] my-[20px] md:text-[22px] text-[18px] font-bold text-Textdarkcolor w-[90%]">
          Summary
        </p>
        <div className="pt-0 pb-1 lg:w-[70%] w-[90%] custom-scrollbar">
          <form>
            {carts.map((item, index) => {
              const perDayPrice = item.price_perday == 0 ? item.price_perday :
                summaryCardData.seasonal_pricing && Array.isArray(summaryCardData.seasonal_pricing) && summaryCardData.seasonal_pricing.length > 0
                  ? summaryCardData.seasonal_pricing.find(i => i.category_id === item.category_id)?.value ?? item.price_perday
                  : item.price_perday;
              return (
                <div
                  className="bg-white-500 rounded-xl shadow-[0px_8px_32px_#00000014] m-4 py-4 border-black-600 text-center"
                  key={index}
                >
                  <div className=" grid lg:grid-cols-3 lg:gap-5 gap-0 md:grid-cols-2 grid-cols-1 w-[90%] mx-auto ">
                    <div className="">
                      <p className=" md:text-[18px] text-[14px]">Category</p>
                      <p className="lg:mt-[24px] mt-[4px] md:text-[16px] text-[12px]  text-Inputtextcolor capitalize">
                        {item.cart_type}
                      </p>
                    </div>
                    <div>
                      <p className="md:mt-0 mt-1 md:text-[18px] text-[14px]">
                        Passenger
                      </p>
                      <p className="lg:mt-[24px] mt-[4px] md:text-[16px] text-[12px] text-Inputtextcolor">
                        {item.category.name}
                      </p>
                    </div>
                    <div>
                      <p className="md:mt-0 mt-1 md:text-[18px] text-[14px]">
                        Price per Day
                      </p>
                      <p className="lg:mt-[24px] mt-[4px] md:text-[16px] text-[12px] text-Inputtextcolor">
                        ${perDayPrice}
                      </p>
                    </div>
                  </div>
                  <p className="md:my-[20px] my-[10px]  text-Appcolor md:text-[18px] text-[14px]">
                    {item.start_date || item.end_date !== undefined
                      ? `${moment(item.start_date).format(
                        "MMM, DD YYYY"
                      )} - ${moment(item.end_date).format("MMM, DD YYYY")}`
                      : `${moment(startDate).format("MMM, DD YYYY")} - ${moment(
                        endDate
                      ).format("MMM, DD YYYY")}`}
                  </p>
                </div>
              );
            })}

            {Value.map((item, ind) => {
              return (
                <div
                  key={ind}
                  className={`${item.title === "Payment Method"
                    ? "cursor-pointer items-center  md:text-[16px] text-[12px]"
                    : " " && item.title === "Additional Driver(s) Email"
                      ? "cursor-pointer items-center h-auto"
                      : " "
                    } bg-white-500 rounded-xl shadow-[0px_8px_32px_#00000014] m-4 py-4 border-black-600 text-center md:text-[20px] text-[15px]`}
                  onClick={
                    item.title === "Payment Method" ? item.onClick : null
                  }
                >
                  <div>
                    <>
                      {item.title === "Additional Driver(s) Email" ? (
                        <div className="flex justify-between items-center ">
                          <p className="text-Textdarkcolor flex  px-5">
                            {item.title}
                          </p>
                          <p
                            className=" text-white mr-5 bg-Appcolor py-2 px-4 rounded-md md:text-[15px] text-[12px]"
                            onClick={
                              item.title === "Additional Driver(s) Email" &&
                              item.onClick
                            }
                          >
                            Add{" "}
                          </p>
                        </div>
                      ) : (
                        <p className="text-Textdarkcolor flex  px-5">
                          {item.title}
                        </p>
                      )}
                      <div
                        className={`${item.content == Payment
                          ? "justify-center px-5 py-2"
                          : " justify-start" && item.content == emailValues
                            ? "px-5 py-2"
                            : "flex px-5 py-1"
                          } `}
                      >
                        {item.content === Coupon ? (
                          couponValue ? (
                            <div className="md:text-[16px] text-[12px] justify-start text-green-700">
                              {"Congrats! Coupon code " +
                                couponValue.code +
                                (couponValue.operation === "percentage"
                                  ? " (" + couponValue.value + "%" + ")"
                                  : " (" + "$" + couponValue.value + ")") +
                                " applied successfully"}
                            </div>
                          ) : (
                            <div className="flex justify-between w-[100%]">
                              <input
                                type="text"
                                placeholder="Enter coupon code"
                                value={couponCode}
                                onChange={(e) => setCouponCode(e.target.value)}
                                className="border-[1px] rounded-md outline-none p-2 w-[100%] md:text-[15px] text-[12px]"
                              />
                              <p
                                onClick={() => handleCoupon()}
                                className="ml-2 text-white cursor-pointer justify-center items-center bg-Appcolor p-2 md:text-[15px] text-[12px] rounded-md"
                              >
                                Apply
                              </p>
                            </div>
                          )
                        ) : ind == 0 ? (
                          _renderPaymentCard()
                        ) : (
                          <p
                            className={`${item.title === "Additional Driver(s) Email"
                              ? "text-Appcolor font-semibold text-start -mt-2 md:text-[16px] text-[12px]"
                              : "text-Textlitecolor md:text-[16px] text-[12px]"
                              }`}
                          >
                            {item.title === "Additional Driver(s) Email" ? (
                              <div>
                                {Array.isArray(item.content) &&
                                  item.content.map((emailObject, index) => (
                                    <div
                                      key={index}
                                      className="flex justify-between items-center mt-1  "
                                    >
                                      <span>{emailObject.email}</span>
                                      <img
                                        src={Bin}
                                        className="w-3 h-3 mr-6"
                                        onClick={() => handleDeleteEmail(index)}
                                      />
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              item.content ?? item.emptyMessage
                            )}
                          </p>
                        )}
                      </div>
                      {couponMessage === "Coupon" && (
                        <div
                          className={`text-${couponMessage.includes("Invalid") ? "red" : "green"
                            }-500`}
                        >
                          {" "}
                          {couponMessage}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              );
            })}

            <div className="rounded-xl border-black-600 text-center w-full">
              <div className="bg-[#00000014] rounded-xl shadow-[0px_8px_32px_#00000014] m-4 py-4 border-black-600 text-center ">
                <div className="flex  flex-col text-[16px] w-[95%] mx-auto  ">
                  {carts.map((item, ind) => {
                    const perDayPrice = item?.price_perday == 0 ? item.price_perday :
                      summaryCardData.seasonal_pricing && Array.isArray(summaryCardData.seasonal_pricing) && summaryCardData.seasonal_pricing.length > 0
                        ? summaryCardData.seasonal_pricing.find(i => i.category_id === item.category_id)?.value ?? item.price_perday
                        : item.price_perday;

                    let startDates =
                      item.start_date !== undefined
                        ? new Date(item.start_date)
                        : new Date(startDate);
                    let endDates =
                      item.end_date !== undefined
                        ? new Date(item.end_date)
                        : new Date(endDate);
                    let differenceInMs = endDates - startDates;
                    let differenceInDays = differenceInMs / (1000 * 3600 * 24);

                    totalDifferenceInDays += differenceInDays;
                    totaldaysDiff = differenceInDays;
                    let subtotalForItem =
                      differenceInDays *
                      perDayPrice.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      });
                    totalSubtotal += subtotalForItem;
                    return (
                      <div
                        key={ind}
                        className="flex pt-[7px] justify-between md:text-[16px] text-[12px]"
                      >
                        <p className="text-Textlitecolor">{`Total Price (${parseInt(
                          differenceInDays
                        )} Days LSV ${ind + 1})`}</p>
                        <p className="text-Textlitecolor">{`$ ${(
                          differenceInDays * perDayPrice
                        ).toFixed(2)}`}</p>
                      </div>
                    );
                  })}
                  <div className="flex pt-[7px]  justify-between md:text-[16px] text-[12px]">
                    <p className="text-Textlitecolor">{`Total Selected Days`}</p>
                    <p className="text-Textlitecolor">
                      {parseInt(totalDifferenceInDays)}
                    </p>
                  </div>
                  {renderItems()}
                </div>
              </div>
            </div>

            <div className=" w-[90%] mx-auto my-5">
              <div className="mt-5">
                <div className="flex">
                  {!agreementCheck && !responseSignatureData ? (
                    <input
                      id={1}
                      name={"checkbox"}
                      onClick={
                        !agreementCheck && !responseSignatureData
                          ? openModal
                          : undefined
                      }
                      checked={agreementCheck}
                      disabled={
                        !agreementCheck && !responseSignatureData ? false : true
                      }
                      type={"checkbox"}
                      placeholder={"checkbox"}
                    />
                  ) : (
                    <input
                      id={1}
                      name={"checkbox"}
                      checked={agreementCheck}
                      type={"checkbox"}
                      placeholder={"checkbox"}
                    />
                  )}
                  <label
                    htmlFor={""}
                    className={
                      "pl-3 md:text-[16px] text-[12px] text-Textdarkcolor font-medium"
                    }
                  >
                    <span>
                      I acknowledge and agree to terms of this
                      <a
                        onClick={
                          !agreementCheck && !responseSignatureData
                            ? openModal
                            : undefined
                        }
                        className={`underline cursor-pointer pl-1 text-Appcolor hover:text-blue-600 visited:text-purple-600 ${agreementCheck && responseSignatureData
                          ? "disabled-link"
                          : ""
                          }`}
                      >
                        Rental Agreement.
                      </a>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {
              isPgRequired == 1 && (
                <div className=" w-[90%] mx-auto my-5">
                  <div className="mt-5">
                    <p>Riders under the age of 18? Please complete & sign this form.</p>
                    <div className="flex flex-row gap-1 mt-5">

                      <button
                        type="button"
                        style={{
                          backgroundColor: parentalAgreement && !is_18_select ? '#0075FF' : 'gray',
                        }}
                        disabled={parentalAgreement}
                        onClick={() => {
                          if (!parentalAgreement && !pgResponse) {
                            openParentalModal(false);
                          }
                        }}
                        className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                      >
                        No
                      </button>
                      <button
                        type="button"
                        style={{
                          backgroundColor: parentalAgreement && is_18_select ? '#0075FF' : 'gray',

                        }}
                        disabled={parentalAgreement}
                        className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
                        onClick={() => {
                          if (!parentalAgreement && !pgResponse) {
                            openParentalModal(true);
                          }
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          </form>
        </div>
        <div className="flex justify-between w-[90%] md:my-10 my-5 lg:gap-0 gap-2">
          <button
            className="bg-Btncolor text-Textdarkcolor lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[120px] h-[40px]"
            onClick={() => {
              setCurrentStep(3);
              switchComponent("Searchcartone");
            }}
          >
            Back
          </button>
          <button
            className={`${successModal || !agreementCheck
              ? "bg-gray-400  "
              : "bg-Appcolor  "
              }  text-white font-semibold lg:text-[16px] text-[12px] rounded-[10px] lg:w-[145px] lg:h-[50px] md:w-[130px] md:h-[40px] w-[120px] h-[40px]`}
            onClick={() => booking()}
            disabled={!agreementCheck}
          >
            Proceed To Pay
          </button>
        </div>
        <Modal
          SummaryPage={true}
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
        />
        <EmailModal
          emailModal={emailModal}
          setShowemailmodal={setShowemailmodal}
          setEmailValues={setEmailValues}
        />

        <SummaryAggrementmodal
          showModal={showModal}
          setShowModal={setShowModal}
          isFromSummary={true}
          setAgreementCheck={setAgreementCheck}
          onResponseData={handleResponseData}
          onResponseSignatureData={handleResponseSignatureData}
          summaryData={summaryCardData}
        />
        <PgAgreementModal
          showModal={parentalModal}
          setShowModal={setParentalModal}
          isFromSummary={true}
          is_18_select={is_18_select}
          setAgreementCheck={setParentalAgreement}
          onResponseSignatureData={(data) => setPgResponse(data)}
          data={summaryCardData.parental_agreement}
        />
        <Successmodal
          successModal={successModal}
          setShowsuccessModal={setShowsuccessModal}
        />
        <ToastContainer limit={1} />
      </div>
    );
  }
};

export default Summary;